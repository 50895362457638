<template>
  <div>
    <breadcrumb
      :nameNav="$t('breadcrumbs.vass.recording_requests.recording_requests')"
      :searchQuery="searchQuery"
      @itemFilterSearch="addItemFilterSearch"
    />

    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      :item-edit.sync="itemEdit"
      :is-add.sync="isAdd"
      @refetch-data="refetchData"
      :cameras-options="camerasOptions"
      :recording-request-status-options="recordingRequestStatusOptions"
      :tags-options="tagsOptions"
      :isEnabled="isEnabled"
    />

    <div class="modal" v-if="showModal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <img :src="selectedImage" alt="Large Image" />
      </div>
    </div>

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search') + '...'"
              />
              <b-button
                variant="secondary"
                @click="openAdd()"
                v-if="$can('request_record_video', 'Video')"
              >
                <span class="text-nowrap text-capitalize">{{
                  `${$t("actions.add")} ${$tc("recording_request.title", 1)}`
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>
        <template #head(description)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(created)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(updated)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
                :badge="
                  !data.item.viewed && $can('manage_request_record_video', 'Video')
                    ? 1
                    : 0
                "
                badge-classes="bg-danger"
              />
            </template>

            <b-dropdown-item
              :to="`/show_recording/${data.item.id}`"
              v-if="
                (data.item.id_status == 2 &&
                  moment(data.item.authorized_from) <= moment() &&
                  moment(data.item.authorized_to) >= moment()) ||
                $can('manage_request_record_video', 'Video')
              "
            >
              <feather-icon icon="VideoIcon" />
              <span class="align-middle ml-50 text-capitalize">{{
                $t("recording_request.recording")
              }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="openEdit(data.item)"
              v-if="$can('manage_request_record_video', 'Video')"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50 text-capitalize"
                >{{ $t("actions.edit") }}
              </span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50 text-capitalize">{{
                $t("actions.delete")
              }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <template v-slot:cell(media_image)="data">
          <div
            v-for="(media_image, index) in data.item.media_image"
            :key="index"
            class="media-list mt-1"
          >
            <img
              v-if="checkType(media_image.mime_type)"
              :src="extractUrl(media_image.url)"
              alt=""
              width="80px"
              @click="openModal(extractUrl(media_image.url))"
            />
          </div>
        </template>
        <template v-slot:cell(media_file)="data">
          <div
            v-for="(media_file, index) in data.item.media_file"
            :key="index"
            class="media-list mt-1"
          >
            <a v-if="!checkType(media_file.mime_type)" :href="extractUrl(media_file.url)">
              {{ media_file.name }}
            </a>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import moment from "moment-timezone";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import store from "@/store";
import Pagination from "@/components/Pagination.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    Sidenav,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBadge,
    Breadcrumb,
    Pagination,
    vSelect,
  },
  setup(_, context) {
    const showModal = ref(false); // Estado del modal (abierto o cerrado)
    const selectedImage = ref(""); // URL de la imagen seleccionada

    function openModal(imageUrl) {
      selectedImage.value = imageUrl; // Establece la URL de la imagen seleccionada
      showModal.value = true; // Abre el modal
    }

    function closeModal() {
      showModal.value = false; // Cierra el modal
    }
    const checkType = (type) => {
      return type.includes("image");
    };
    const extractUrl = (url) => {
      return url.replace("http://localhost", "http://local.api.omniview.mx");
    };
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);
    const isEnabled = ref(false);

    const openEdit = (item) => {
      isSidebarActive.value = true;
      itemEdit.value = item;
      isAdd.value = false;
      isEnabled.value = true;
    };

    const { t } = useI18nUtils();
    const toast = useToast();

    const trans = {
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
      "messages.import.title": t("messages.import.title"),
      "messages.import.upload": t("messages.import.upload"),
      "messages.import.uploading_file": t("messages.import.uploading_file"),
      "messages.import.upload_succesfully": t("messages.import.upload_succesfully"),
    };

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      camerasOptions,
      tagsOptions,
      recordingRequestStatusOptions,
      recordingRequestStatusFilter,
    } = useList();

    const openAdd = () => {
      isAdd.value = true;
      isSidebarActive.value = true;
      isEnabled.value = false;
    };

    const nav = t("breadcrumbs.vass.recording_requests.recording_requests");

    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nav === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: trans["messages.delete.title"],
        text: trans["messages.delete.body"],
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: trans["actions.delete"],
        cancelButtonText: trans["actions.cancel"],
        customClass: {
          confirmButton: "btn btn-primary text-capitalize",
          cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("recording_request/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    return {
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,
      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      camerasOptions,
      tagsOptions,
      recordingRequestStatusOptions,
      isEnabled,
      moment,
      addItemFilterSearch,
      confirmDelete,

      extractUrl,
      checkType,
      showModal,
      selectedImage,
      openModal,
      closeModal,
    };
  },
};
</script>

<style lang="scss" scoped>
.media-list {
  list-style: none;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  padding: 20px;
  max-width: 800px;
  max-height: 80vh;
  overflow: auto;
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  color: #aaa;
  cursor: pointer;
}

.close:hover {
  color: #000;
}

.modal-content img {
  max-width: 50%;
  max-height: 50%;
}
</style>