import { ref, computed, watch } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment-timezone";
import store from "@/store";
import router from "@/router";
import { debounce } from "lodash";

export default function useList() {
  const refListTable = ref(null);

  const toast = useToast();

  store.dispatch("cm_proxy/getAll", {'deviceType': 'camera'});

  const camerasOptions = computed(() => store.getters["cm_proxy/getSelectedItems"]);
  const camerasFilter = ref(null);

  store.dispatch("bbva_request_tag/getAll");
  const tagsOptions = computed(
    () => store.getters["bbva_request_tag/getSelectedItemsTag"]
  );
  const tagsFilter = ref(null);

  store.dispatch("recording_request_status/getAll");
  const recordingRequestStatusOptions = computed(
    () => store.getters["recording_request_status/getSelectedItems"]
  );
  const recordingRequestStatusFilter = ref(null);

  if (router.currentRoute.params.id_camera)
    camerasFilter.value = parseInt(router.currentRoute.id_camera);

  const tableColumns = [
    { label: "actions.name", key: "actions" },
    { label: "image", key: "media_image" },
    { label: "recording_request.files", key: "media_file" },
    { label: "recording_request.folio", key: "id", sortable: true },
    { label: "recording_request.status", key: "status.status", sortable: true },
    { label: "recording_request.requested", key: "created", sortable: true },
    { label: "recording_request.description", key: "description", sortable: true },
    { label: "users.title", key: "user.email", sortable: true },
    { label: "camera.title", key: "camera.name", sortable: true },
    { label: "recording_request.tag", key: "tag.description", sortable: true },
    { label: "recording_request.start", key: "from", sortable: true },
    { label: "recording_request.finish", key: "to", sortable: true },
    { label: "recording_request.authorized_by", key: "authorized.email", sortable: true },
    {
      label: "recording_request.authorized_from",
      key: "authorized_from",
      sortable: true,
    },
    { label: "recording_request.authorized_to", key: "authorized_to", sortable: true },
    { label: "recording_request.comment", key: "comment", sortable: true },
  ];

  const perPage = ref(10);
  const totalList = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    };
  });

  const fetchList = (ctx, callback) => {
    store
      .dispatch("recording_request/getAll", {
        q: searchQuery.value,
        max: perPage.value,
        "page[n]": currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        callback(response.data);
        totalList.value = response.meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error obteniendo la información",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const formatDateAssigned = (value) => {
    const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
    return formattedDate;
  };

  const refetchData = () => {
    refListTable.value.refresh();
  };

  watch(
    [recordingRequestStatusFilter, camerasFilter, currentPage, perPage, ],
    () => {
      refetchData();
    }
  );

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,
    camerasOptions,
    camerasFilter,
    tagsOptions,
    tagsFilter,
    recordingRequestStatusOptions,
    recordingRequestStatusFilter,
    refetchData,
  };
}
