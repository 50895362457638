<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0 text-capitalize">
          {{
            `${isAdd ? `${$t("actions.add")}` : `${$t("actions.edit")}`} ${$tc(
              "recording_request.title",
              1
            )}`
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Camera"
            rules="required"
          >
            <b-form-group
              :label="$tc('camera.title', 2)"
              label-for="id_camera"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_camera"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="camerasOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="camera_id"
                :disabled="isEnabled"
                @search="handlesearch"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- description -->
          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('recording_request.description')"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="itemData.description"
                :state="getValidationState(validationContext)"
                trim
                :disabled="isEnabled"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- From -->
          <validation-provider #default="validationContext" name="From" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('recording_request.start')"
              label-for="From"
            >
              <flat-pickr
                id="from"
                v-model="itemData.from"
                class="form-control"
                :state="getValidationState(validationContext)"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:S', time_24hr: true }"
                :disabled="isEnabled"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!--  To -->
          <validation-provider #default="validationContext" name="To" rules="required">
            <b-form-group
              class="text-capitalize"
              :label="$t('recording_request.finish')"
              label-for="To"
            >
              <flat-pickr
                id="to"
                v-model="itemData.to"
                class="form-control"
                :state="getValidationState(validationContext)"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:S', time_24hr: true }"
                :disabled="isEnabled"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Etiqueta -->
          <validation-provider
            #default="validationContext"
            name="Etiqueta"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('recording_request.tag')"
              label-for="etiqueta"
            >
              <v-select
                v-model="itemData.id_tag"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tagsOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_tag"
                :disabled="isEnabled"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
            v-if="$can('manage_request_record_video', 'Video') && !isAdd"
            vid="status"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('recording_request.status')"
              label-for="Status"
            >
              <v-select
                v-model="itemData.id_status"
                :options="recordingRequestStatusOptions"
                :clearable="false"
                :reduce="(val) => val.value"
                input-id="id_status"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!--Authorized From -->
          <validation-provider
            #default="validationContext"
            name="Authorized from"
            rules="required_if:status,2"
            v-if="$can('manage_request_record_video', 'Video') && itemData.id_status == 2"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('recording_request.authorized_from')"
              label-for="Authorized from"
            >
              <flat-pickr
                id="authorized_from"
                v-model="itemData.authorized_from"
                class="form-control"
                :state="getValidationState(validationContext)"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:S', time_24hr: true }"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Authorized to -->
          <validation-provider
            #default="validationContext"
            name="Authorized to"
            rules="required_if:status,2"
            v-if="$can('manage_request_record_video', 'Video') && itemData.id_status == 2"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('recording_request.authorized_to')"
              label-for="Authorized to"
            >
              <flat-pickr
                id="authorized_to"
                v-model="itemData.authorized_to"
                class="form-control"
                :state="getValidationState(validationContext)"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:S', time_24hr: true }"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- comment -->
          <validation-provider
            #default="validationContext"
            name="comment"
            rules="required"
            v-if="$can('manage_request_record_video', 'Video') && itemData.id_status == 3"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('recording_request.comment')"
              label-for="comment"
            >
              <b-form-input
                id="comment"
                v-model="itemData.comment"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div>
            <label class="mb-2 text-capitalize">{{ $t("files") }}</label>
            <vue-dropzone
              id="dropzone_logo"
              ref="dropzoneImageLogo"
              :options="dropzoneOptions"
              class="dropzone-main"
              @vdropzone-file-added="addFileImageLogo"
              @vdropzone-removed-file="removeFileImageLogo"
            />
          </div>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ $t("actions.save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
              {{ $t("actions.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import { debounce } from "lodash";
import { XIcon } from "feather-icons";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  components: {
    XIcon,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    vueDropzone: vue2Dropzone,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    camerasOptions: {
      type: Array,
      required: true,
    },
    tagsOptions: {
      type: Array,
      required: true,
    },
    recordingRequestStatusOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    isEnabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },

  setup(props, { emit }) {
    const dropzoneOptions = ref({
      url: "https://httpbin.org/post",
      paramName: "file",
      autoProcessQueue: false,
      dictDefaultMessage: "",
      addRemoveLinks: true,
      dictRemoveFile: "delete",
      acceptedFiles: "image/*, .pdf, .doc, .docx, .xlsx",
      maxFiles: 3,
    });
    const dropzoneImageLogo = ref(null);

    const addFileImageLogo = (file) => {
      dropzoneImageLogo.value = file;
    };
    const removeFileImageLogo = () => {
      dropzoneImageLogo.value = null;
    };

    const blankData = {
      id_camera: 0,
      description: "",
      from: "",
      to: "",
      id_tag: "",
      id_status: 0,
      authorized_from: null,
      authorized_to: null,
    };
    const handlesearch = debounce(async function (query, loading) {
      if (query.length) {
        loading(true);
        await store.dispatch("cm_proxy/getAll", { q: query });
        loading(false);
      }
    }, 400);

    const toast = useToast();
    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        itemData.value.id_status = 1;

        const data = new FormData();

        data.append("id_camera", itemData.value.id_camera);
        data.append("description", itemData.value.description);
        data.append("from", itemData.value.from);
        data.append("to", itemData.value.to);
        data.append("id_tag", itemData.value.id_tag);
        data.append("id_status", itemData.value.id_status);

        const acceptedFiles = dropzoneImageLogo.value.getAcceptedFiles();

        for (let i = 0; i < acceptedFiles.length; i++) {
          if (acceptedFiles[i] instanceof File) {
            data.append(`files${i}`, acceptedFiles[i]);
          }
        }
        data.append("file_number", acceptedFiles.length);

        dispatch = store.dispatch("recording_request/add", data);
      } else {
        itemData.value.id_camera = itemData.value.id_camera.value;

        const req = {
          id: itemData.value.id,
          params: itemData.value,
        };

        dispatch = store.dispatch("recording_request/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          if (response.response.status == 422) {
            let string = ``;
            Object.entries(response.response.data.data).forEach((entry) => {
              const [key, value] = entry;
              string += `${key} - ${value}<br>`;
            });
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `${response.response.data.message}`,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: string,
                },
              },
              {
                timeout: 10000,
              }
            );
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        }).finally(() => {
            itemData.value.id_camera = {
              label: props.itemEdit.camera.name,
              value: props.itemEdit.id_camera,
            };
          }
        );
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;

        itemData.value.id_camera = {
          label: props.itemEdit.camera.name,
          value: props.itemEdit.id_camera,
        };
      }
    };

    return {
      dropzoneOptions,
      dropzoneImageLogo,

      addFileImageLogo,
      removeFileImageLogo,
      itemData,
      onSubmit,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      handlesearch,
      toast,
    };
  },
};
</script>